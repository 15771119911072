import React from 'react'
import AboutImg from "../../assets/about.png";
import PaperSvg from "../../assets/notes.png";
import AndreImg from "../../assets/Andre.jpg";
import TobiasImg from "../../assets/Tobias.jpg";
import MawienImg from "../../assets/Mawien.jpg";
import BeneImg from "../../assets/Benjamin.jpg";
import VanessaImg from "../../assets/Vanessa.jpg";
import RalfImg from "../../assets/Ralf.jpg";
import AlexImg from "../../assets/Alex.jpg";
import LinkedImg from "../../assets/linkedin2.png";
import './About.css';

const About = () => {
  return (
    <div>
    <div className="about__wrapper" id="about_section">
        <div className="left">
            <div>
                <h3>Wer ist <img src={AboutImg} alt="" /> ?</h3>
            </div>
            <div>
                <p>
                Pull statt Push, Community statt Abo, digital statt stationär lautet der Leitsatz des Münchner
                Startups. Das Team um Gründer-Duo André Moll und Tobias Neuburger betreibt mit Utry.me
                den weltweit ersten und einzigen Online-Probiermarkt ohne Preise.
                </p>
                <p>
                Im Mai 2018 an den
                Start gegangen, ist Utry.me das aktuell wachstumsstärkste Sampling Start-Up in Europa.
                Das mittlerweile 40-köpfige Team hat es sich zur Mission gemacht, bis 2025 die größte
                europäische FMCG-Sampling-Plattform zu werden und dabei als Game Changer der
                Branche zu agieren.
                </p>
            </div>
        </div>
        <div className="right">
            <img src={PaperSvg} alt="" />
            
        </div>
</div> <div className="about__wrapper2">


        <div className="moreabout">
            <div>
                <h3>Wer sind wir?</h3>
            </div>
            <div className="about__us">
             <div className="profile">   
             <img src={AndreImg} alt="" /><br />
             <h4>André Moll</h4>
             GF / Founder  Utry.Me<br /><br />
             <a href="https://www.linkedin.com/in/andr%C3%A9-moll-24484683/" target="_new" alt="André Moll auf LinkedIn"><img src={LinkedImg} alt="" className="linkedIn" /></a>
             </div>
             
             <div className="profile">
             <img src={TobiasImg} alt="" />
             <h4>Tobias Neuburger</h4>
             GF / Founder  Utry.Me<br /><br />
             <a href="https://www.linkedin.com/in/tobias-neuburger-b684a7175/" target="_new" alt="Tobias Neuburger auf LinkedIn"><img src={LinkedImg} alt="" className="linkedIn" /></a>
             </div>
             
             <div className="profile">
             <img src={MawienImg} alt="" />
             <h4>Mawien Bol</h4>
            NFT Advisor<br />Founder Tomorrow Seeds<br />
            <a href="https://www.linkedin.com/in/mawien-bol-791005187/" target="_new" alt="Mawien Bol auf LinkedIn"><img src={LinkedImg} alt="" className="linkedIn" /></a>
            
             
             </div>
             
             <div className="profile">
             <img src={BeneImg} alt="" />
                <h4>Benjamin Neuburger</h4>
                Programmierung<br />Projektbegleitung
             </div>
             
             <div className="profile">
             <img src={VanessaImg} alt="" />
                <h4>Vanessa Marchner</h4>
                Leitung Grafikdesign</div>
             
             <div className="profile">
             <img src={RalfImg} alt="" />
                <h4>Ralf Kuchler</h4>
                Redaktion
             </div>
             
             <div className="profile">
             <img src={AlexImg} alt="" />
                <h4>Alex Morgenroth</h4>
                Grafikdesign</div>
             </div>
            
         </div>
        </div>
    </div>

    
  )
}

export default About