import React from "react";
import PropTypes from "prop-types";

const VideoEmbed = ({ embedId }) => (
    <div className="video-responsive-wrapper">
  <div className="video-responsive">
    <iframe
      src={`https://www.loom.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded Loom"
    /></div>
  </div>
);

VideoEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default VideoEmbed;